import React from "react"
import { graphql } from "gatsby"

import Layout from "@components/Layout"
import SEO from "@components/Seo"
import Testimonials from "@components/TestimonialGrid"

const TestimonialsPage = ({ data }) => {
  const banner = data.banner.edges[0].node.childImageSharp.fluid
  const transformations = data.transformations.edges
  return (
    <Layout fluid={banner} alt={"Neil Hill Coaching"} height={300}>
      <SEO title="Testimonials" />
      <Testimonials testimonials={transformations} />
    </Layout>
  )
}

export const query = graphql`
  query TestimonialsPageQuery {
    banner: allFile(filter: { name: { eq: "testimonials_1440x400" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }

    transformations: allFile(
      filter: { relativeDirectory: { eq: "transformations" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

export default TestimonialsPage
