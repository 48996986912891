import React from "react"
import Img from "gatsby-image"
import Styled from "styled-components"

import { H1 } from "../StyledElements"

const Testimonial = Styled(Img)`
    opacity: 0.7;
    transform: scale(0.9);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        opacity: 1;
        transform: scale(1.0);
    }
`

const shuffleArray = arr => {
  const newArr = arr.slice()
  for (let i = newArr.length - 1; i > 0; i--) {
    const rand = Math.floor(Math.random() * (i + 1))
    ;[newArr[i], newArr[rand]] = [newArr[rand], newArr[i]]
  }
  return newArr
}
const TestimonialGrid = ({ testimonials }) => {
  const shuffled = shuffleArray(testimonials)
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 pt-3 text-center">
          <H1>Y3T Transformations</H1>
        </div>
      </div>
      <div className="row">
        {shuffled.map(testimonial => {
          console.log(testimonial)
          const {
            node: {
              childImageSharp: { fluid },
              id,
            },
          } = testimonial
          return (
            <div key={id} className="col-12 col-md-6 col-lg-4">
              <Testimonial fluid={fluid} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TestimonialGrid
